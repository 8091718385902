import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Sku } from '@/models/index'

@Model()
export default class CardStock extends ApplicationRecord {
  static jsonapiType = 'card_stocks'

  @Attr() name!: string
  @Attr() inStockCount!: number
  @Attr() toBuyCount!: number
  @Attr() reservedInStockCount!: number
  @Attr() sharedInStockCount!: number

  @BelongsTo() manageable!: any
  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() sku!: Sku
}
