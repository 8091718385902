import { Model, Attr, BelongsTo } from 'spraypaint'
import { Notification } from 'element-ui'

import ApplicationRecord from '@/models/ApplicationRecord'
import Manageable from '@/models/Manageable'
import Invoice from '@/models/Invoice'

@Model()
export default class BankTransfer extends ApplicationRecord {
  static jsonapiType = 'bank_transfers'

  @Attr() amount!: number
  @Attr() reference!: string
  @Attr() sendAt!: Date
  @Attr() createdAt!: Date
  @Attr() cancelledAt!: Date | null
  @Attr() proofOfPayment!: string

  @BelongsTo() manageable!: Manageable
  @BelongsTo() invoice!: Invoice

  async cancel() {
    const isDestroyed = await this.destroy()

    this.cancelledAt = isDestroyed ? new Date() : null
    Notification({
      title: 'Success',
      message: 'Topup Successfully cancelled',
      type: 'success',
      duration: 2000
    })
  }
}
