import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Advertiser, Caretaker, GiftCard, GiftCardDiscount } from '@/models/index'

@Model()
export default class GiftCardRate extends ApplicationRecord {
  static jsonapiType = 'gift_card_rates'

  @Attr() rate!: number

  @BelongsTo() advertiser!: Advertiser
  @BelongsTo() caretaker!: Caretaker
  @BelongsTo() giftCard!: GiftCard

  @HasMany() discounts!: GiftCardDiscount[]
}
