import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Manageable } from '@/models/index'

@Model()
export default class User extends ApplicationRecord {
  static jsonapiType = 'users'
  static endpoint = '/user'
  static singularResource = true

  @Attr() email!: string
  @Attr() unconfirmedEmail!: string
  @Attr() firstname!: string
  @Attr() lastname!: string
  @Attr() password!: string
  @Attr() passwordConfirmation!: string
  @Attr() manageableType!: string

  @BelongsTo() manageable!: Manageable
}
