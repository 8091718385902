import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Rate } from '@/models/index'

@Model()
export default class RateChangeSchedule extends ApplicationRecord {
  static jsonapiType = 'rate_change_schedules'

  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() applyStatus!: string
  @Attr() applicableAt!: Date
  @Attr() newAmount!: number

  @BelongsTo() rate!: Rate
}
