import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Seller from '@/models/Seller'
import Wholesaler from '@/models/Wholesaler'

@Model()
export default class PaymentMode extends ApplicationRecord {
  static jsonapiType = 'payment_modes'

  @Attr() mode!: string
  @Attr() defferalAmountCap!: number
  @Attr() defferalDuration!: number

  @BelongsTo() seller!: Seller
  @BelongsTo() wholesaler!: Wholesaler
}
