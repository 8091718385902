import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import { useUserStore } from './stores/user'
import { usePermissionStore } from './stores/permission'
import i18n from '@/lang' // Internationalization
import settings from './settings'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/reset-password']

const getPageTitle = (key: string) => {
  const hasKey = i18n.te(`route.${key}`)
  if (hasKey) {
    const pageName = i18n.t(`route.${key}`)
    return `${pageName} - ${settings.title}`
  }
  return `${settings.title}`
}

router.beforeEach(async (to: Route, _: Route, next: any) => {
  const userStore = useUserStore()
  const permissionStore = usePermissionStore()

  // Start progress bar
  NProgress.start()
  let nextRoute = to.path === '/login' ? undefined : to.query.redirect
  let replace = false

  const authorizationCode = to.query.code

  if (authorizationCode) {
    await userStore.signinRedirectCallback()

    nextRoute = '/'
    replace = true
  }

  await userStore.getUser()

  // Determine whether the user has logged in
  if (userStore.user) {
    if (to.path === '/login') nextRoute = '/'
    else if (!permissionStore.routesGenerated) {
      await userStore.getManageable()
      // Note: roles must be a object array! such as: ['admin'] or ['developer', 'editor']
      const roles = userStore.roles

      // Generate accessible routes map based on role
      permissionStore.generateRoutes(roles)
      // Dynamically add accessible routes
      permissionStore.dynamicRoutes.forEach(r => router.addRoute(r))

      next(to.path)
    }
  } else {
    const toPath = (nextRoute || to.path).toString()
    if (whiteList.indexOf(toPath) == -1) {
      nextRoute = `/login?redirect=${encodeURIComponent(to.fullPath)}`
    }
  }

  next({ path: nextRoute, replace: replace })
})

router.afterEach((to: Route) => {
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done()

  // set page title
  document.title = getPageTitle(to?.meta?.title)
})
