import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Balance extends ApplicationRecord {
  static jsonapiType = 'file_mappers'

  @Attr() createdAt!: Date
  @Attr() amount!: number
  @Attr() default!: boolean
  @Attr() useCents!: boolean
  @Attr() headers!: boolean
  @Attr() dateFormat!: string
  @Attr() charDelimiter!: string
  @Attr() fields!: any
}
