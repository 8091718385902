import { Model, Attr, BelongsTo, HasMany, HasOne } from "spraypaint";

import ApplicationRecord from '@/models/ApplicationRecord'
import { Customer, GiftCard, CardDistribution, GiftCardRate, MultiChoiceCard, Gift } from '@/models/index'

@Model()
export default class CustomerOrder extends ApplicationRecord {
  static jsonapiType = 'customer_orders'

  @Attr({ persist: false }) createdAt!: string
  @Attr() amount!: number
  @Attr() discountedAmount!: number
  @Attr() payableAmount!: number
  @Attr() selledAmount!: number
  @Attr() selledRate!: number
  @Attr() discountAmount!: number
  @Attr() discountRate!: number
  @Attr() customerEmail!: string
  @Attr() customerFirstname!: string
  @Attr() customerLastname!: string
  @Attr() programId!: string
  @Attr() multiChoiceCardsAmount!: number
  @Attr() creditCardPan!: string
  @Attr() creditCardBrand!: string
  @Attr({ persist: false }) number!: string
  @Attr({ persist: false }) status!: string
  @Attr({ persist: false }) deliveryStatus!: string
  @Attr({ persist: false }) paymentStatus!: string
  @Attr({ persist: false }) paymentRemoteStatus!: string
  @Attr({ persist: false }) paymentRemoteErrorCode!: string
  @Attr({ persist: false }) paymentRemoteErrorMessage!: string
  @Attr({ persist: false }) paymentFormToken!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() customer!: Customer
  @BelongsTo() giftCardRate!: GiftCardRate

  @HasMany() cardDistributions!: CardDistribution[]
  @HasMany() multiChoiceCards!: MultiChoiceCard[]

  @HasOne() gift!: Gift
  // @HasOne() sentGift!: SentGift

  public isDelivered() {
    return this.deliveryStatus === 'delivered'
  }

  public isInDelivery() {
    return this.deliveryStatus === 'in_delivery'
  }

  public isPaid() {
    return this.paymentStatus === 'paid'
  }

  public isPaymentFailure() {
    return this.paymentStatus === 'failure'
  }

  public isFulfilled() {
    return this.status === 'fulfilled'
  }

  public isProcessing() {
    return this.status === 'processing'
  }

  public payableAmountWithDiscount(): number {
    if (this.giftCard?.discounts.length === 0) return 0
    return this.amount * ((100 - this.giftCard.discounts[0].discount) / 100)
  }

  public discountOrder(): number {
    if (this.giftCard?.discounts.length === 0) return 0
    return this.amount - (this.amount * (this.giftCard.discounts[0].discount / 100))
  }

  public getColorByName() {
    let name = ''
    let hash = 0

    if (this.customer?.firstname && this.customer?.lastname) name = this.customer.firstname + this.customer.lastname

    if (name.length === 0) return hash
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }
}
