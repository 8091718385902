import { Model, Attr, BelongsTo } from 'spraypaint'
import ApplicationRecord from '@/models/ApplicationRecord'

import { GiftCard } from '@/models/index'

@Model()
export default class SaleVolume extends ApplicationRecord {
  static jsonapiType = 'sale_volumes'

  @Attr() createdAt!: Date
  @Attr() facialValueAmount!: number
  @Attr() multiChoiceCardAmount!: number
  @Attr() discountAmount!: number
  @Attr() averageDiscountRate!: number
  @Attr() commissionAmount!: number
  @Attr() averageCommissionRate!: number
  @Attr() marginAmount!: number
  @Attr() averageMarginRate!: number
  @Attr() sales!: number

  @BelongsTo() giftCard!: GiftCard
}
