import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker, GiftCard } from '@/models/index'

@Model()
export default class MultiChoiceGiftCard extends ApplicationRecord {
  static jsonapiType = 'multi_choice_gift_cards'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() internalName!: string
  @Attr() createdAt!: string
  @Attr() logo!: string
  @Attr() bannerImage!: string
  @Attr() eligibleGiftCardsCount!: number

  @BelongsTo() caretaker!: Caretaker
  @HasMany() giftCards!: GiftCard[]
}
