import { Model, SpraypaintBase, MiddlewareStack } from 'spraypaint'

import router from '@/router/index'
import { Notification } from 'element-ui'
import { useUserStore } from '@/stores/user'

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl: string = process.env.VUE_APP_API_URL || 'http://localhost:80'
  static apiNamespace = '/market/v1'
  static jwtStorage = false as const

  static generateAuthHeader(token: string) {
    return `Bearer ${token}`
  }
}

const middleware: any = new MiddlewareStack()

middleware.afterFilters.push(async(response: any, json: any, requestOptions: any) => {
  const userStore = useUserStore()

  // Catch Also 403 ? (Bearer token not found)
  if (response.status === 401) {
    try {
      await userStore.signout()
    } catch (err) {
      if (router.currentRoute.path !== '/login') {
        router.push('/login')

        Notification.warning({
          title: 'Token expired',
          message: 'Token expired, you need to login again',
          type: 'warning',
          duration: 5000
        })
      }
      // eslint-disable-next-line no-throw-literal
      throw ('abort')
    }
  } else if (response.status >= 400) {
    for (const e of json.errors) {
      Notification.error({
        title: e.title,
        message: e.detail,
        type: 'error',
        duration: 5000
      })
    }
    // eslint-disable-next-line no-throw-literal
    throw ('abort')
  }
})

ApplicationRecord.middlewareStack = middleware

export default ApplicationRecord
