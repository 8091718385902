import VueRouter, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    tagView: false               if false, the page will be hidden in tag-view (default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: 'dashboard',
          affix: true
        }
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    meta: { hidden: true },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/index.vue'),
        name: 'Profile',
        meta: {
          title: 'profile',
          icon: 'user',
          noCache: true
        }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = [
  {
    path: '/orders',
    component: Layout,
    meta: {
      roles: ['wholesaler', 'buyer', 'seller', 'caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/index.vue'),
        name: 'Orders',
        meta: {
          title: 'orders',
          icon: 'shopping'
        }
      }
    ]
  },
  {
    path: '/brands',
    component: Layout,
    meta: {
      roles: ['wholesaler', 'buyer', 'caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "brands" */ '@/views/brands/index.vue'),
        name: 'Brands',
        meta: {
          title: 'brands',
          icon: 'shop'
        }
      },
      {
        path: ':id/view',
        component: () => import(/* webpackChunkName: "brands" */ '@/views/brands/brand-view/index.vue'),
        name: 'BrandView',
        meta: {
          title: 'brandView',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/invoices',
    component: Layout,
    meta: {
      roles: ['seller', 'wholesaler', 'buyer']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/index.vue'),
        name: 'Invoices',
        meta: {
          title: 'invoices',
          icon: 'invoice'
        }
      }
    ]
  },
  {
    path: '/prepaid-accounts',
    component: Layout,
    meta: {
      roles: ['wholesaler']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "buyersManagement" */ '@/views/prepaid-account/index.vue'),
        name: 'PrepaidAccounts',
        meta: {
          title: 'prepaidAccounts',
          icon: 'euro'
        }
      },
      {
        path: ':id/view',
        component: () => import(/* webpackChunkName: "buyersManagement" */ '@/views/prepaid-account/BalanceMovesView/index.vue'),
        name: 'PrepaidAccountView',
        meta: {
          title: 'prepaidAccountView',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/prepaid-account',
    component: Layout,
    meta: {
      roles: ['buyer']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "buyersManagement" */ '@/views/prepaid-account/BalanceMovesView/index.vue'),
        name: 'PrepaidAccountView',
        meta: {
          icon: 'euro',
          title: 'prepaidAccountView'
        }
      }
    ]
  },
  {
    path: '/purchase',
    component: Layout,
    meta: {
      roles: ['caretaker'],
      excludeAccounts: ['Lyf']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "purchase" */ '@/views/purchase/index.vue'),
        name: 'Purchase',
        meta: {
          title: 'purchase',
          icon: 'shopping'
        }
      }
    ]
  },
  {
    path: '/volume-buyers',
    component: Layout,
    meta: {
      roles: ['seller']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "volumeByBuyers" */ '@/views/volume-buyers/index.vue'),
        name: 'ConfirmedBuyers',
        meta: {
          title: 'volumeByBuyers',
          icon: 'peoples'
        }
      }
    ]
  },
  {
    path: '/rates-management',
    component: Layout,
    meta: {
      roles: ['caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "ratesManagement" */ '@/views/rates-management/index.vue'),
        name: 'RatesManagement',
        meta: {
          title: 'ratesManagement',
          icon: 'form'
        }
      }
    ]
  },
  {
    path: '/file-mapper',
    component: Layout,
    meta: {
      roles: ['seller']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "fileMapper" */ '@/views/file-mapper/index.vue'),
        name: 'FileMapper',
        meta: {
          title: 'fileMapper',
          icon: 'excel'
        }
      }
    ]
  },
  {
    path: '/stocks',
    component: Layout,
    meta: {
      roles: ['buyer', 'wholesaler']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "stocks" */ '@/views/stocks/index.vue'),
        name: 'Stocks',
        meta: {
          title: 'stocks',
          icon: 'stock'
        }
      }
    ]
  },
  {
    path: '/notifications',
    component: Layout,
    meta: {
      roles: ['caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications/index.vue'),
        name: 'Notifications',
        meta: {
          title: 'notifications',
          icon: 'notification'
        }
      }
    ]
  },
  {
    path: '/multi-choice-cards',
    component: Layout,
    meta: {
      roles: ['caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "MultiChoiceCards" */ '@/views/multi-choice-cards/index.vue'),
        name: 'MultiChoiceCards',
        meta: {
          title: 'MultiChoiceCards',
          icon: 'gift'
        }
      }
    ]
  },
  {
    path: '/gifts',
    component: Layout,
    meta: {
      roles: ['caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "gifts" */ '@/views/gifts/index.vue'),
        name: 'Gifts',
        meta: {
          title: 'operation',
          icon: 'give-gift'
        }
      }
    ]
  },
  {
    path: '/purchase-infos',
    component: Layout,
    meta: {
      roles: ['wholesaler']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "purchaseInfos" */ '@/views/purchase-infos/index.vue'),
        name: 'PurchaseInfos',
        meta: {
          title: 'purchaseInfos',
          icon: 'give-gift'
        }
      }
    ]
  },
  // {
  //   path: '/cards',
  //   component: Layout,
  //   meta: {
  //     roles: ['wholesaler']
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "cards" */ '@/views/cards/index.vue'),
  //       name: 'Cards',
  //       meta: {
  //         title: 'cards',
  //         icon: 'cards'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/buyers-management',
    component: Layout,
    meta: {
      roles: ['wholesaler']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "buyersManagement" */ '@/views/buyers-management/index.vue'),
        name: 'BuyersManagement',
        meta: {
          title: 'buyersManagement',
          icon: 'peoples'
        }
      },
      {
        path: ':id/view',
        component: () => import(/* webpackChunkName: "buyersManagement" */ '@/views/buyers-management/BuyerView/index.vue'),
        name: 'BuyersManagementView',
        meta: {
          title: 'buyersManagementView',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/customers',
    component: Layout,
    meta: {
      roles: ['caretaker']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "customers" */ '@/views/customers/index.vue'),
        name: 'Customers',
        meta: {
          title: 'customers',
          icon: 'peoples'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  }
]

const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
