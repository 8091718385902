import { Attr, Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Buyer } from '@/models/index'

@Model()
export default class PullRequest extends ApplicationRecord {
  static jsonapiType = 'pull_requests'

  @Attr() amount!: number
  @Attr() status!: string
  @Attr() requestId!: string
  @Attr() createdAt!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() buyer!: Buyer
}
