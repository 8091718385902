import { Model, Attr } from 'spraypaint'
import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Volume extends ApplicationRecord {
  static jsonapiType = 'volumes'

  @Attr() name!: string
  @Attr() type!: string
  @Attr() volumes!: any

  public sumTotalVolumes() {
    let sum = 0
    for (const el in this.volumes) sum += this.volumes[el]
    return sum
  }

  public sumYearVolumes(year: string) {
    let sum = 0
    for (const el in this.volumes) {
      if (el.includes(year)) sum += this.volumes[el]
    }
    return sum
  }
}
