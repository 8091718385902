import { Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Wholesaler, Buyer } from '@/models/index'

@Model()
export default class WholesalerBuyer extends ApplicationRecord {
  static jsonapiType = 'wholesaler_buyers'

  @BelongsTo() wholesaler!: Wholesaler
  @BelongsTo() buyer!: Buyer
}
