import { Model, Attr, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

import { RateCard } from '@/models/index'

@Model()
export default class Seller extends ApplicationRecord {
  static jsonapiType = 'sellers'

  @Attr() name!: string
  @Attr() logo!: string

  @HasMany() rateCards!: RateCard[]
}
