import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class MyBuyersBalanceExtract extends ApplicationRecord {
  static jsonapiType = 'my_buyers_balance_extracts'

  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() file!: string
  @Attr() per!: string
}
