import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { RateCard, RateChangeSchedule } from '@/models/index'

@Model()
export default class Rate extends ApplicationRecord {
  static jsonapiType = 'rates'

  @Attr() name!: string
  @Attr() amount!: number
  @Attr() requirementValue!: number
  @Attr() requirementOperator!: string

  @BelongsTo() rateCard!: RateCard

  @HasMany() rateChangeSchedules!: RateChangeSchedule[]
}
