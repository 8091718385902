import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Order from '@/models/Order'
import request from '@/utils/request'

@Model()
export default class Delivery extends ApplicationRecord {
  static jsonapiType = 'deliveries'

  @Attr() createdAt!: string
  @Attr() activateAt!: string
  @Attr() expireAt!: string
  @Attr() codesFile!: string
  @Attr() bid!: string
  @Attr() batchInProcess!: boolean
  @Attr() amount!: string
  @Attr() match!: boolean
  @Attr() totalCodesNumber!: string
  @Attr() distributedCodesNumber!: string
  @Attr() firstDownloadedAt!: string
  @Attr() lastDownloadedAt!: string
  @Attr() distributedByApiCount!: number
  @Attr() distributedByFileCount!: number
  @Attr() fileValid!: any
  @Attr({ persist: false }) distribution!: any
  @Attr({ persist: false }) fileErrors!: any

  @BelongsTo() order!: Order

  get codeFileName() {
    return `${Object.keys(this.distribution).map((distribution: any) => `${this.distribution[distribution]}x${distribution / 100}e`).join('_')}.csv`
  }

  get distributionArray(): any[] {
    return Object.keys(this.distribution).map((distribution: any) => {
      return { quantity: this.distribution[distribution], amount: distribution / 100 }
    })
  }

  get deliverySum(): number {
    return Object.keys(this.distribution).reduce((acc: number, distribution: any): number => {
      return acc + (Number(this.distribution[distribution]) * (Number(distribution) / 100))
    }, 0)
  }

  async download(): Promise<Delivery> {
    return await request.get(`/market/v1/deliveries/${this.id}/download`)
  }
}
