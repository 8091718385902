import {
  Model,
  Attr,
  BelongsTo
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Balance, Order } from '@/models/index'

@Model()
export default class MyBuyersBalanceMove extends ApplicationRecord {
  static jsonapiType = 'my_buyers_balance_moves'

  @Attr() createdAt!: Date
  @Attr() amount!: number
  @Attr() faceValueAmount !: number
  @Attr() discountWithoutVat !: number
  @Attr() discount !: number
  @Attr() dueAt!: string
  @Attr() creditedAt!: string
  @Attr() reason!: string
  @Attr() sourceType!: string
  @Attr() sourceId!: string

  @BelongsTo() order!: Order
  @BelongsTo() balance!: Balance
  @BelongsTo() source!: any
}
