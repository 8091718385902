import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Gift extends ApplicationRecord {
  static jsonapiType = 'sent_gifts'

  @Attr() id!: string
  @Attr() createdAt!: string
  @Attr() senderType!: string

  @BelongsTo() recipient!: any
}
