import { Model, Attr, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Balance, Location } from '@/models/index'

@Model()
export default class Buyer extends ApplicationRecord {
  static jsonapiType = 'buyers'

  @Attr() name!: string
  @Attr() siren!: string
  @Attr() code!: string
  @Attr() rcs!: string
  @Attr() intraVatNumber!: string
  @Attr() commissionNumberFormat!: string
  @Attr() logo!: string

  @HasOne() location!: Location
  @HasOne() balance!: Balance
}
