import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Buyer from '@/models/Buyer'

@Model()
export default class Advertiser extends ApplicationRecord {
  static jsonapiType = 'advertisers'

  @Attr() name!: string
  @Attr() subdomain!: string

  @BelongsTo() buyer!: Buyer
}
