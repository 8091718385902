import {
  Model,
  Attr,
  BelongsTo,
  HasMany
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Order from '@/models/Order'
import GiftCard from '@/models/GiftCard'
import Buyer from '@/models/Buyer'
import CardDistribution from '@/models/CardDistribution'

@Model()
export default class ChildrenOrder extends ApplicationRecord {
  static jsonapiType = 'children_orders'

  @Attr() amountCents!: number
  @Attr() bankTransferRef!: string
  @Attr() status!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() buyer!: Buyer
  @BelongsTo() order!: Order

  @HasMany() cardDistributions!: CardDistribution[]
}
