import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Balance } from '@/models/index'

@Model()
export default class PurchaseVolume extends ApplicationRecord {
  static jsonapiType = 'purchase_volumes'

  @Attr() purchaseCount!: number
  @Attr() faceValueAmount!: number
  @Attr() amount!: number
  @Attr() giftCardId!: string
  @Attr() updatedAt!: string
  @Attr() createdAt!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() balance!: Balance
}
