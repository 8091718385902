import { Model, Attr, HasMany, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Manageable, Rate } from '@/models/index'

@Model()
export default class RateCard extends ApplicationRecord {
  static jsonapiType = 'rate_cards'

  @Attr() name!: string
  @Attr() requirement!: string
  @Attr() default!: boolean
  @Attr() vatIncluded!: boolean

  @BelongsTo() manageable!: Manageable

  @HasMany() rates!: Rate[]
}
