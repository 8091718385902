import { Model, Attr, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Program } from '@/models/index'

@Model()
export default class Notification extends ApplicationRecord {
  static jsonapiType = 'notifications'

  @HasMany() programs!: Program[]

  @Attr() kind!: string
  @Attr() startDate!: Date
  @Attr() endDate!: Date
  @Attr() homeBanner!: boolean
  @Attr() title!: string
  @Attr() description!: string
  @Attr() content!: string
  @Attr() createdAt!: Date
  @Attr() bannerImage!: string

  @Attr({ persist: false }) bannerImageBlob!: any
}
