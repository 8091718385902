import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker } from '@/models/index'

@Model()
export default class Program extends ApplicationRecord {
  static jsonapiType = 'programs'

  @Attr() name!: string
  @Attr() description!: string
  @Attr() createdAt!: string
  @Attr() logo!: string
  @Attr() splashScreenLogo!: string
  @Attr() splashScreenBackground!: string
  @Attr() splashScreenBackgroundColor!: string
  @Attr() domainWhitelist!: string[]

  @BelongsTo() caretaker!: Caretaker
}
