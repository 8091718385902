import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Manageable } from '@/models/index'

@Model()
export default class Account extends ApplicationRecord {
  static jsonapiType = 'accounts'

  @Attr() firstname!: string
  @Attr() lastname!: string
  @Attr() email!: string
  @Attr() ignoreEmails!: boolean
  @Attr() manageableId!: string
  @Attr() manageableType!: boolean

  @BelongsTo() manageable!: Manageable
}
