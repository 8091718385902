import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class BalanceExtract extends ApplicationRecord {
  static jsonapiType = 'balance_extracts'

  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() file!: string
  @Attr() per!: string
}
