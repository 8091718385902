import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker, BudgetMove } from '@/models/index'

@Model()
export default class Budget extends ApplicationRecord {
  static jsonapiType = 'budgets'

  @Attr() id!: string
  @Attr() amount!: number

  @BelongsTo() caretaker!: Caretaker
  @HasMany() budgetMoves!: BudgetMove[]
}
