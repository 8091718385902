import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker } from '@/models/index'
import request from '@/utils/request'

@Model()
export default class DotationOperation extends ApplicationRecord {
  static jsonapiType = 'dotation_operations'

  @Attr() id!: string
  @Attr() bid!: string
  @Attr() batchInProcess!: boolean
  @Attr() name!: string
  @Attr() donorName!: string
  @Attr() donorLogo!: string
  @Attr() bannerImage!: string
  @Attr() giftBoxColor!: string
  @Attr() giftRibbonColor!: string
  @Attr() identifier!: string
  @Attr() externalIdentifier!: string
  @Attr() maximumAmount!: number
  @Attr() totalUsedAmount!: number
  @Attr() totalIssuedAmount!: number
  @Attr() dotationDuration!: string
  @Attr() cseKind!: string
  @Attr() preIssue!: boolean
  @Attr() startAt!: string
  @Attr() endAt!: string
  @Attr() createdAt!: string

  @BelongsTo() caretaker!: Caretaker

  get remainingAmount(): number {
    return this.maximumAmount - this.totalIssuedAmount
  }

  async pollBatchStatus(): Promise<any> {
    return await request.get(`/batch_status/${this.bid}.json`)
  }

  public async preIssueCards(multiChoiceCardDistributions: any): Promise<any> {
    const params = {
      data: {
        id: this.id,
        type: 'dotation_operations',
        multi_choice_card_distributions: multiChoiceCardDistributions
      }
    }

    const { data } = await request.put(`/market/v1/dotation_operations/${this.id}/pre_issue`, params)

    return data
  }

  public async give(multiChoiceGiftCardId: string, customerId: string, amount: number): Promise<any> {
    const params = {
      data: {
        id: this.id,
        type: 'dotation_operations',
        multi_choice_gift_card_id: multiChoiceGiftCardId,
        customer_id: customerId,
        amount: amount
      }
    }

    const { data } = await request.put(`/market/v1/dotation_operations/${this.id}/give`, params)

    return data
  }

  budgetRemainingPercentage(): number {
    const percentage = Number((100 - (((this.maximumAmount - this.remainingAmount) / this.maximumAmount) * 100)).toFixed(2))

    if (percentage < 0) return 0
    else return percentage
  }

  usagePercentage(): number {
    if (this.totalUsedAmount === 0) return 0
    else return Number(((this.totalUsedAmount / (this.maximumAmount - this.remainingAmount)) * 100).toFixed(2))
  }
}
