import {
  Model,
  Attr,
  HasMany,
  HasOne
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Seller, Sku, GiftCardDiscount, GiftCardSeller, CardStock, PurchaseInfo } from '@/models/index'
@Model()
export default class GiftCard extends ApplicationRecord {
  static jsonapiType = 'gift_cards'

  @Attr() id!: string
  @Attr() code!: string
  @Attr() name!: string
  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() baseline!: string
  @Attr() description!: string
  @Attr() onlineUsable!: boolean
  @Attr() offlineUsable!: boolean
  @Attr() howToUseOnline!: string
  @Attr() howToUseOffline!: string
  @Attr() termOfUse!: string
  @Attr() websiteUrl!: string
  @Attr() barcodeSymbology!: number
  @Attr() validityPeriod!: number
  @Attr() checkBalanceUrl!: string
  @Attr() domUsable!: boolean
  @Attr() btocAvailable!: boolean
  @Attr() splittableOnline!: boolean
  @Attr() splittableOffline!: boolean
  @Attr() cumulativeOnline!: boolean
  @Attr() cumulativeOnlineLimit!: number
  @Attr() cumulativeOffline!: boolean
  @Attr() cumulativeOfflineLimit!: number
  @Attr() cumulativeWithVoucher!: boolean
  @Attr() codePin!: boolean
  @Attr() barcodeByConcatenation!: boolean
  @Attr() apiEnabled!: boolean
  @Attr() buyerRate!: number

  @Attr() logo!: string
  @Attr() logoSquare!: string
  @Attr() logoPrimaryColor!: string

  @HasMany() giftCardSellers!: GiftCardSeller[]
  @HasOne() defaultGiftCardSeller!: GiftCardSeller
  @HasMany() sellers!: Seller[]

  @HasMany() skus!: Sku[]
  @HasMany() discounts!: GiftCardDiscount[]
  @HasMany() cardStocks!: CardStock[]
  @HasMany() purchaseInfos!: PurchaseInfo[]
}
