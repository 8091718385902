import {
  Model,
  Attr
} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Wholesaler extends ApplicationRecord {
  static jsonapiType = 'wholesalers'

  @Attr() name!: string
  @Attr() logo!: string
}
