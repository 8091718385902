import { Model, Attr, BelongsTo, HasOne } from 'spraypaint'
import ApplicationRecord from '@/models/ApplicationRecord'

import { GiftCard, CardStock } from '@/models/index'

@Model()
export default class Sku extends ApplicationRecord {
  static jsonapiType = 'skus'

  @Attr() faceValue!: number
  @Attr() faceValueFree!: boolean
  @Attr() faceValueMin!: number
  @Attr() faceValueMax!: number
  @Attr() cardImage!: string
  @Attr() discardedAt!: string

  @BelongsTo() giftCard!: GiftCard
  @HasOne() cardStock!: CardStock
}
