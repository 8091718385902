import { Attr, Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Advertiser, GiftCard, GiftCardRate, Program } from '@/models/index'

@Model()
export default class GiftCardDiscount extends ApplicationRecord {
  static jsonapiType = 'gift_card_discounts'

  @Attr() discount!: number
  @Attr() rateId!: string
  @Attr() updatedAt!: string

  @BelongsTo() advertiser!: Advertiser
  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() program!: Program
  @BelongsTo() rate!: GiftCardRate

  static byProgramId(id: string) {
    return this.where({ programId: id })
  }
}
