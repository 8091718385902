import { Model, Attr, HasMany, BelongsTo, HasOne } from 'spraypaint'
import ApplicationRecord from '@/models/ApplicationRecord'
import { BankTransfer, Buyer, GiftCard, Order, Seller } from '@/models/index'

@Model()
export default class Invoice extends ApplicationRecord {
  static jsonapiType = 'invoices'

  @Attr() createdAt!: Date
  @Attr() issuedAt!: Date
  @Attr() mode!: string
  @Attr() name!: string
  @Attr() amount!: string
  @Attr() chargeableAmount!: string
  @Attr() paidAmount!: number
  @Attr() payableAmount!: number
  @Attr() overpaidAmount!: number
  @Attr() underpaidAmount!: number
  @Attr() number!: string
  @Attr() customNumber!: string
  @Attr() paymentStatus!: string
  @Attr() paymentLimitAt!: string
  @Attr() paymentMode!: string
  @Attr() invoicePer!: string
  @Attr() file!: string
  @Attr() comment!: string

  @HasMany() orders!: Order[]
  @HasMany() bankTransfers!: BankTransfer[]

  @BelongsTo() seller!: Seller
  @BelongsTo() buyer!: Buyer

  @HasOne() giftCard!: GiftCard
  @HasOne() commissionInvoice!: Invoice
}
