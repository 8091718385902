import { Model, Attr, BelongsTo, HasMany, HasOne } from 'spraypaint'

import {
  Delivery,
  BankTransfer,
  BalanceMove,
  Invoice,
  CardDistribution,
  Buyer,
  GiftCard,
  Seller,
  Wholesaler
} from "@/models/index";
import ApplicationRecord from '@/models/ApplicationRecord'
import request from '@/utils/request'

import { Notification } from 'element-ui'
import i18n from '@/lang'

@Model()
export default class Order extends ApplicationRecord {
  static jsonapiType = 'orders'

  @Attr() number!: string
  @Attr() customNumber!: string
  @Attr() amount!: number
  @Attr() amountCents!: number
  @Attr() paymentMode!: string
  @Attr() reservedStock!: boolean
  @Attr() prepareToDownload!: boolean
  @Attr({ persist: false }) balanceBurned!: number
  @Attr({ persist: false }) discount!: number
  @Attr({ persist: false }) discountWithoutVat!: number
  @Attr({ persist: false }) discountedAmount!: number
  @Attr({ persist: false }) discountedAmountCents!: number
  @Attr({ persist: false }) discountedAmountWithoutVat!: number
  @Attr({ persist: false }) chargeableAmount!: number
  @Attr({ persist: false }) payableAmount!: number
  @Attr({ persist: false }) balanceEarned!: number
  @Attr({ persist: false }) deferredAmountUsage!: number
  @Attr({ persist: false }) status!: string
  @Attr({ persist: false }) paymentStatus!: string
  @Attr({ persist: false }) deliveryStatus!: string
  @Attr({ persist: false }) retrievalMode!: string
  @Attr({ persist: false }) deliveryMode!: string
  @Attr({ persist: false }) paymentLimitAt!: string
  @Attr({ persist: false }) createdAt!: string

  @Attr({ persist: false }) mayDestroy!: boolean

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() buyer!: Buyer
  @BelongsTo() seller!: Seller
  @BelongsTo() wholesaler!: Wholesaler

  @HasMany() invoices!: Invoice[]
  @HasMany() orders!: Order[]
  @HasMany() cardDistributions!: CardDistribution[]
  @HasMany() deliveries!: Delivery[]
  @HasOne() balanceMove!: BalanceMove

  public async process() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }
    const { data } = await request.put(`/market/v1/orders/${this.id}/to_process`, params)
    this.status = data.attributes.status
  }

  public async paymentByBalance() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }
    const { data } = await request.put(`/market/v1/orders/${this.id}/pay_with_balance`, params)
    this.paymentStatus = data.attributes.payment_status
    this.payableAmount = data.attributes.payable_amount
  }

  public async switchInvoice(invoiceId: string) {
    const params = {
      data: {
        id: this.id,
        type: 'orders',
        invoice_id: invoiceId
      }
    }
    const { data } = await request.put(`/market/v1/orders/${this.id}/switch_invoice`, params)
  }

  public async downloadCodeFile(delivery: Delivery): Promise<any> {
    const { data } = await request.get(`/market/v1/deliveries/${delivery.id}/download`)
    return data.attributes
  }

  public async suspend() {
    const params = {
      data: { id: this.id, type: 'orders' }
    }

    try {
      const { data } = await request.put(`/market/v1/orders/${this.id}/suspend`, params)
      this.status = data.attributes.status

      Notification({
        title: i18n.t('orders.notification.suspendSuccess.title') as string,
        message: i18n.t('orders.notification.suspendSuccess.message') as string,
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: i18n.t('orders.notification.suspendError.title') as string,
        message: i18n.t('orders.notification.suspendError.message') as string,
        type: 'error',
        duration: 2000
      })

      throw e
    }
  }

  public async resume() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/market/v1/orders/${this.id}/resume`, params)
      this.status = data.attributes.status

      Notification({
        title: i18n.t('orders.notification.resumeSuccess.title') as string,
        message: i18n.t('orders.notification.resumeSuccess.message') as string,
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: i18n.t('orders.notification.resumeError.title') as string,
        message: i18n.t('orders.notification.resumeError.message') as string,
        type: 'error',
        duration: 2000
      })

      throw e
    }
  }

  get codeFileName(): string {
    return `${this.number}_${this.cardDistributions.map((cd) => `${cd.quantity}x${cd.amount}e`).join('_')}.csv`
  }

  public isApiRetrieval(): boolean {
    return this.retrievalMode === 'api_retrieval'
  }

  public isApiDeliveryMode(): boolean {
    return this.deliveryMode === 'api_delivery'
  }

  public isPhysical(): boolean {
    return this.retrievalMode === 'physical_retrieval' && this.deliveryMode === 'physical_delivery'
  }

  public isProcessing(): boolean {
    return this.status === 'processing'
  }

  public isCancelled(): boolean {
    return this.status === 'cancelled'
  }

  public isSuspended(): boolean {
    return this.status === 'suspended'
  }

  public canSuspend(): boolean {
    return this.isApiRetrieval() && this.isProcessing()
  }

  public canResume(): boolean {
    return this.isApiRetrieval() && this.isSuspended()
  }

  public bankTransferSum(): number {
    if (!this.standardInvoice() || this.standardInvoice()!.bankTransfers.length === 0) return 0
    return this.standardInvoice()!.bankTransfers.reduce((acc: number, current: BankTransfer): number => {
      return acc + current.amount
    }, 0)
  }

  public deliveriesSum(): number {
    if (this.deliveries.length === 0) return 0
    return this.deliveries.reduce((acc: number, current: Delivery): number => {
      return acc + Number(current.amount)
    }, 0)
  }

  public deliveriesCodesTotalSum(): number {
    if (this.cardDistributions.length === 0) return 0
    return this.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + Number(current.quantity)
    }, 0)
  }

  public cardDistributionsSum(): number {
    if (this.cardDistributions.length === 0) return 0
    return this.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + (Number(current.quantity) * Number(current.amount))
    }, 0)
  }

  public deliveriesCodesSum(): number {
    if (this.deliveries.length === 0) return 0
    return this.deliveries.reduce((acc: number, current: Delivery): number => {
      if (current.match) return acc + Number(current.totalCodesNumber)
      return acc
    }, 0)
  }

  public paymentStatusMessage(): string {
    if (!this.standardInvoice()) return 'Aucune facture émise'
    const offset = Math.abs(this.payableAmount - this.bankTransferSum()).toFixed(2)
    if (this.isOverPaid()) return `Excédent de paiement de ${offset}€`
    else if (this.standardInvoice()!.bankTransfers.length > 0 && this.isUnderPaid()) return `Paiement insuffisant, veuillez compléter de ${offset}€`
    return 'Aucun paiement enregistré'
  }

  public isTotalyPaid(): boolean {
    return this.bankTransferSum() === this.payableAmount
  }

  public isByAPI(): boolean {
    return this.retrievalMode == 'api_retrieval' && this.deliveryMode == 'api_delivery'
  }

  public isOverPaid(): boolean {
    return this.bankTransferSum() > this.payableAmount
  }

  public isUnderPaid(): boolean {
    return this.bankTransferSum() < this.payableAmount
  }

  public payableAmountleft(): string {
    return (this.payableAmount - this.bankTransferSum()).toFixed(2)
  }

  public isPaid(): boolean {
    return this.paymentStatus === 'paid'
  }

  public isUnpaid(): boolean {
    return this.paymentStatus === 'unpaid'
  }

  public isRefunded(): boolean {
    return this.paymentStatus === 'refunded'
  }


  public isPartiallyPaid(): boolean {
    const bankTransferSum = this.bankTransferSum()
    return !this.isPaid() && bankTransferSum > 0 && bankTransferSum < this.payableAmount
  }

  public isDelivered(): boolean {
    return this.deliveryStatus === 'delivered'
  }

  public isInDelivery(): boolean {
    return this.deliveryStatus === 'in_delivery'
  }

  public paidAt() {
    if (this.isPaidByPrepaidAccount()) return this.balanceMove?.createdAt
    return this.standardInvoice()?.bankTransfers[0]?.sendAt
  }

  public isPaidByPrepaidAccount(): boolean {
    return this.balanceBurned === this.chargeableAmount
  }

  public isPrepaid(): boolean {
    return this.paymentMode === 'prepaid'
  }

  public isWaiting(): boolean {
    return this.status === 'waiting'
  }

  public isFulfilled(): boolean {
    return this.status === 'fulfilled'
  }

  public isDeferred(): boolean {
    return this.paymentMode === 'differed'
  }

  public deliveryAt() {
    if (this.deliveries.length > 0) return this.deliveries[0].createdAt
    return null
  }

  public activateAt() {
    if (this.deliveries.length > 0) return this.deliveries[0].activateAt
    return null
  }

  public showTooltipPayment(): boolean {
    return ((this.isPaid() || this.isPartiallyPaid()) && !this.isByAPI()) || (this.isDeferred() && !!this.standardInvoice())
  }

  public showTooltipDelivery(): boolean {
    return this.deliveryStatus === 'delivered' && !this.isByAPI()
  }

  public detailsNeeded(): boolean {
    return this.mustHaveDetails() && this.cardDistributions.length === 0
  }

  public mustHaveDetails(): boolean {
    return (this.retrievalMode === 'file_retrieval') &&
      ((this.standardInvoice()?.paymentMode === 'prepaid' && this.bankTransferSum() > 0) || this.standardInvoice()?.paymentMode === 'differed')
  }

  public standardInvoice(): Invoice | undefined {
    if (this?.invoices.length === 0) return undefined
    return this.invoices.find((invoice: Invoice) => invoice.mode === 'standard')
  }
}
