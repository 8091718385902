import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { MultiChoiceGiftCard } from '@/models/index'

@Model()
export default class DotationOperationReport extends ApplicationRecord {
  static jsonapiType = 'dotation_operation_reports'

  @Attr() issuedCount!: number
  @Attr() givenCount!: number
  @Attr() expiredCount!: number
  @Attr() amount!: number

  @BelongsTo() multiChoiceGiftCard!: MultiChoiceGiftCard
}
