import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { BalanceExtract, Buyer, GiftCard, Seller } from '@/models/index'

@Model()
export default class Balance extends ApplicationRecord {
  static jsonapiType = 'balances'

  @Attr() amount!: number
  @Attr() amountCents!: number
  @Attr() lowAmountThreshold!: number

  @BelongsTo() seller!: Seller
  @BelongsTo() buyer!: Buyer
  @BelongsTo() giftCard!: GiftCard

  @HasMany() balanceExtracts!: BalanceExtract[]
}
