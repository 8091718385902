import { Model, Attr, BelongsTo, HasMany, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import {
  BalanceMove,
  Customer,
  CustomerOrder,
  Delivery,
  Gift,
  GiftCard,
  MyBuyersBalanceMove, PullRequest,
  Sku
} from '@/models/index'

@Model()
export default class Card extends ApplicationRecord {
  static jsonapiType = 'cards'

  @Attr() amount!: number
  @Attr() remainingAmount!: number
  @Attr() pinCode!: string
  @Attr() code!: string
  @Attr() createdAt!: string
  @Attr() activateAt!: string
  @Attr() expireAt!: string
  @Attr() technicalExpireAt!: string
  @Attr() distributedAt!: string
  @Attr() usageStatus!: string
  @Attr() discardedAt!: string
  @Attr() requestId!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() sku!: Sku
  @BelongsTo() customer!: Customer
  @BelongsTo() customerOrder!: CustomerOrder
  @BelongsTo() bulkDelivery!: Delivery
  @BelongsTo() delivery!: Delivery
  @BelongsTo() gift!: Gift

  @HasOne() buyerBalanceMove!: MyBuyersBalanceMove
  @HasOne() pullRequest!: PullRequest

  @HasMany() balanceMoves!: BalanceMove[]
}
