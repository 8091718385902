import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Advertiser, Program } from '@/models/index'

@Model()
export default class Caretaker extends ApplicationRecord {
  static jsonapiType = 'caretakers'

  @Attr() logo!: string
  @Attr() name!: string
  @Attr({ persist: false }) logoBlob!: any

  @BelongsTo() advertiser!: Advertiser
  @HasMany() programs!: Program
}
