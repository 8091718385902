import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Seller, Wholesaler } from '@/models/index'

@Model()
export default class GiftCardSeller extends ApplicationRecord {
  static jsonapiType = 'gift_card_sellers'

  @Attr() default!: boolean
  @Attr() sellerId!: string
  @Attr() wholesalerId!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() seller!: Seller
  @BelongsTo() wholesaler!: Wholesaler
}
